import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import CommentList from "@/views/Index/Components/CommentList.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Step from "@/views/Index/Customization/Components/Step.vue";
import {File} from "@/interface/pagas/common";
import {AddCartCustomizationApi, GetExplain} from "@/network/modules";
import {AddCartCustomizationReq, GetExplanReq} from "@/interface/res";
@Component({
  components: {
    CommentList,
    Step,
    ElImageViewer
  },
  computed: {
  }
})
export default class EmbroideryHomeDetail extends Vue {
  @Provide() imgSrc:string  = '';
  @Provide() code:string  = '';
  @Provide() diyImgs:string  = '';
  @Provide() loading:boolean = false;
  @Provide() diyStep:number = 4;
  @Provide() uploadLoading:boolean = false;
  @Provide() uploadTime:any = null;
  @Provide() uploadLength: number = 0
  @Provide() uploadNum: number = 0
  @Provide() installImgs:Array<string> = [];
  @Provide() customImg:Array<string> = [];
  @Provide() iHeight:string = '';
  @Provide() iHeightSeat:number = 0;
  @Provide() iHeightMsg:string = '';
  @Provide() iWidth:string = '';
  @Provide() remark:string = '';
  @Provide() iWidthSeat:number = 0;
  @Provide() iWidthMsg:string = '';
  @Provide() tabIndex:number = 0;
  @Provide() commentIndex:number = 0;
  @Provide() patternMoerShow:boolean = false;
  @Provide() btnLoading:boolean = false;

  @Provide() showBig:boolean = false;
  @Provide() showBigImgList:Array<any> = [];
  @Provide() showBigImgListIndex:number = 0;




  @Watch('iWidth',{immediate:false, deep:false})
  @Watch('iHeight',{immediate:false, deep:false})
  private iValChange():void {
    const _this:any = this;
    if(_this.$base.isNull(_this.iHeight)){
      _this.diyStep = 4
      return
    }
    if(_this.$base.isNull(_this.iWidth)){
      _this.diyStep = 4
      return
    }
    _this.diyStep = 5

  }

  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.imgSrc = _this.$decryptBy(_this.$route.query.i)
      _this.code = _this.$decryptBy(_this.$route.query.c)
      let imgs = _this.$getDecryptBy('_DIYIMGS')
      if(imgs.indexOf('暂未存储') == -1){
        _this.diyImgs = imgs
      }
      _this.GetExplan()
    }
  }

  mounted(){
    const _this:any = this;
    _this.iHeightSeat = (document as any).getElementById('iHeight').offsetTop
    _this.iWidthSeat = (document as any).getElementById('iWidth').offsetTop
    window.addEventListener('mousewheel',_this.mouseScroll , true)

  }



  destroyed(){
    const _this:any = this;
    // localStorage.removeItem('_DIYIMGS')
  }


  /**
   * @Author HS
   * @Date 2021/8/23 7:37 下午
   * @Description: 加入购物车
   * @Params: null
   * @Return: null
  */
  async shoppingCartFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.iHeight)){
      _this.iHeightMsg = '请输入高度'
      _this.jump(_this.iHeightSeat)
      return
    }
    _this.iHeightMsg = ''

    if(_this.$base.isNull(_this.iWidth)){
      _this.iWidthMsg = '请输入宽度'
      _this.jump(_this.iWidthSeat)
      return
    }
    _this.iWidthMsg = ''
    _this.btnLoading = true
    let str:{installationReality?:string,width:string,height:string,modularImgs:string,type:Number} = {
      installationReality:JSON.stringify(_this.installImgs),
      width:_this.iWidth,
      height:_this.iHeight,
      modularImgs:_this.diyImgs,
      type:0
    }
    if(_this.$base.isNull(_this.installImgs)){
      delete str.installationReality
    }
    let params:AddCartCustomizationReq = {
      highDefinitionUrl:_this.imgSrc,
      customParameter:JSON.stringify(str),
      customizedSeries:1
    }
    const res = await AddCartCustomizationApi(params)
    console.log(res);
    let a = setTimeout(()=>{_this.btnLoading = false;clearTimeout(a)},500)
    const { code, data, msg } = res
    if(code != 200 ){
      if(data.quantityLimitStatus == 1){
        _this.$message.error(msg + '  ' + '储绣家居购物车数量已满');
      }else if(data.quantityLimitStatus == 2){
        _this.$message.error(msg + '  ' + '高级定制购物车数量已满');
      }else if(data.quantityLimitStatus == 0){
        _this.$message.error(msg + '  ' + '丝秘花园购物车数量已满');
      }
      // _this.$message.error(msg);
      return
    }
    _this.$store.commit('UNSHIFT_LIST',JSON.stringify(data))
    let statusData = {
      id:data.cartId,
      ...data,
      code:code,
      time:"待确认"
    }
    console.log(statusData);
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.status.path,'p='+JSON.stringify(statusData))
    _this.$base.goRouter(path)
  }
  async GetExplan(){
    const _this: any = this;
    let params: GetExplanReq = {
      platformType: 2
    }
    const { data, code, msg } = await GetExplain(params);
    _this.customImg = JSON.parse(data)
  }
  /**
   * @Author HS
   * @Date 2021/8/23 4:41 下午
   * @Description: 删除图片
   * @Params: { number ：index - true } [下标]
   * @Return: null
  */
  deleteImgFn(index:number){
    const _this:any = this;
    _this.$refs['image'].uploadFiles.splice(index,1)
    _this.installImgs.splice(index,1)
  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  imgClick(index:number) {
    const _this:any = this;
    _this.showBigImgList = [..._this.installImgs]
    _this.showBigImgListIndex = index
    _this.showBig = true;
  }

  /**
   * @Author HS
   * @Date 2021/8/23 5:26 下午
   * @Description: 打开上传
   * @Params: null
   * @Return: null
  */
  uploadImgFn(){
    const _this:any = this;
    _this.$refs['image'].$children[0].$refs.input.click()
  }
  /**
   * @Author HS
   * @Date 2021/7/26 5:50 下午
   * @Description: 图片上传
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  imageSuccess(response:File){
    const _this:any = this;
    _this.installImgs.push(response.data)
    _this.uploadNum++
    if(_this.uploadLength <= _this.uploadNum){
      _this.uploadLoading = false
    }
    // clearTimeout(_this.uploadTime)
    // _this.uploadTime = setTimeout(()=>{
    //   _this.uploadLoading = false
    //   clearTimeout(_this.uploadTime)
    // },2000)
  }
  /**
   * @Author HS
   * @Date 2021/7/28 3:30 下午
   * @Description: 图片上传超出上线
   * @Params: null
   * @Return: null
   */
  imageExceed(){
    const _this:any = this;
    _this.$message.error("一次最多发送6张图片");
  }
  /**
   * @Author HS
   * @Date 2021/8/24 11:21 上午
   * @Description: 图片上传前
   * @Params: null
   * @Return: null
  */
  imageBeforeUpload(){
    const _this:any = this;
    _this.uploadLoading = true
    _this.uploadNum = 0
  }
  /**
   * @Author HS
   * @Date 2021/10/20 4:20 下午
   * @Description: 获取上传个数
   * @Params: null
   * @Return: null
   */
  imageChange(files:any, fileList:any){
    const _this:any = this;
    let upload_img = document.getElementsByClassName('upload-file')
    if (upload_img && upload_img.length > 0) {
      let upload = upload_img[0].getElementsByTagName('input')
      if (upload && upload.length > 0 && upload[0].files && upload[0].files.length > 0) {
        _this.uploadLength = upload[0].files.length
      }
    }
  }
  /**
   * @Author HS
   * @Date 2021/7/16 9:34 上午
   * @Description: 阻止回到顶部
   * @Params: null
   * @Return: null
   */
  mouseScroll(){
    const _this:any = this;
    clearTimeout(_this.scrollTime);
  }


  /**
   * @Author HS
   * @Date 2021/8/26 11:15 上午
   * @Description: 选型卡切换
   * @Params: { number ：index - true } [下标]
   * @Return:
   */
  tabChangeFn(index:number){
    const _this:any = this;
    _this.tabIndex = index
    const offsetTop = (document as any).getElementById('detailTabs').offsetTop
    _this.jump(offsetTop - 200)
  }

  /**
   * @Author HS
   * @Date 2021/7/16 9:19 上午
   * @Description: 滚动到产品高度
   * @Params: { number ： num - true } [目标位置]
   * @Return: null
   */
  jump (num:number) {
    const _this:any = this;
    let total = num
    let distance = document.documentElement.scrollTop || document.body.scrollTop
    // if(distance < total)return
    // 平滑滚动，时长500ms，每10ms一跳，共50跳
    let step = total / 30
    if (total > distance) {
      smoothDown()
    } else {
      let newTotal = distance - total
      step = newTotal / 50
      smoothUp()
    }
    function smoothDown () {
      if (distance < total) {
        distance += step
        document.body.scrollTop = distance
        document.documentElement.scrollTop = distance
        _this.scrollTime = setTimeout(smoothDown, 6)
      } else {
        document.body.scrollTop = total
        document.documentElement.scrollTop = total
        clearTimeout(_this.scrollTime);
      }
    }
    function smoothUp () {
      if (distance > total) {
        distance -= step
        document.body.scrollTop = distance
        document.documentElement.scrollTop = distance
        _this.scrollTime = setTimeout(smoothUp, 6)
      } else {
        document.body.scrollTop = total
        document.documentElement.scrollTop = total
        clearTimeout(_this.scrollTime);
      }
    }
  }



  /**
   * @Author HS
   * @Date 2021/7/7 2:07 下午
   * @Description: 页面跳转
   * @Params: null
   * @Return: null
   */
  goRoute(){
    const _this:any = this;
    if(_this.$base.isLogin()){
      const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'r=diy&i='+_this.detail.galleryId)
      _this.$base.goRouter(path)
      return
    }
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.status.path,'p='+JSON.stringify({}))
    _this.$base.goRouter(path)
  }

}
